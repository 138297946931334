//import { Authenticator } from '@aws-amplify/ui-react';
import ReCAPTCHA from "react-google-recaptcha";
import '@aws-amplify/ui-react/styles.css';
import React, { useState } from 'react';
//import { useState } from 'react';
import axios from 'axios';

const Main = () => {
    const [isPrecipitacionManual, setIsPrecipitacionManual] = useState(true);
    const [comboboxPrecipitacion, setComboboxPrecipitacion] = useState('');
    const [isPAceleracionManual, setIsPAceleracionManual] = useState(true);
    const [isVisibilidadManual, setIsVisibilidadManual] = useState(true);
    const [isAceleracionManual, setIsAceleracionManual] = useState(true);
    const [comboboxPAceleracion, setComboboxPAceleracion] = useState('');
    const [comboboxAceleracion, setComboboxAceleracion] = useState('');
    const [comboboxVisibilidad, setComboboxVisibilidad] = useState('');
    //const [comboboxDistancia, setComboboxDistancia] = useState('');
    const [isVelocidadManual, setIsVelocidadManual] = useState(true);
    const [isCardiacoManual, setIsCardiacoManual] = useState(true);
    const [comboboxVelocidad, setComboboxVelocidad] = useState('');
    const [comboboxCardiaco, setComboboxCardiaco] = useState('');
    const [mostrarEtiqueta, setMostrarEtiqueta] = useState(true);
    const [resultado, setResultado] = useState('Calculando...');
    const [isClimaManual, setIsClimadaManual] = useState(true);
    const [isTMotorManual, setIsTMotorManual] = useState(true);
    //const [comboboxAngulo, setComboboxAngulo] = useState('');
    const [isCMotorManual, setIsCMotorManual] = useState(true);
    const [captchaValido, setCaptchaValido] = useState(false);
    const [comboboxTMotor, setComboboxTMotor] = useState('');
    const [comboboxCMotor, setComboboxCMotor] = useState('');
    //const [isManualMode, setIsManualMode] = useState(true);
    const [comboboxClima, setComboboxClima] = useState('');
    const [precipitacion, setPrecipitacion] = useState('');
    //const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [paceleracion, setPAceleracion] = useState('');
    const [isRpmManual, setIsRpmManual] = useState(true);
    const API_KEY = "sNEO89xtlw2SeVAqWZLc8wb0EnO6h3AX";
    const [aceleracion, setAceleracion] = useState('');
    const [visibilidad, setVisibilidad] = useState('');
    const [comboboxRpm, setComboboxRpm] = useState('');
    const [color, setColor] = useState('transparent');
    //const [distancia, setDistancia] = useState('');
    const [cargando, setCargando] = useState(false);
    const [velocidad, setVelocidad] = useState('');
    const [longitud, setLongitud] = useState('');
    const [cardiaco, setCardiaco] = useState('');
    const [latitud, setLatitud] = useState('');
    //const [angulo, setAngulo] = useState('');
    const [tmotor, setTmotor] = useState('');
    const [cmotor, setCmotor] = useState('');
    const [onsite, setOnsite] = useState('');
    const [onhour, setOnHour] = useState('');
    const [desing, setDesing] = useState('');
    //const [isManualMode] = useState(true);
    const recaptchaRef = React.createRef();
    const [clima, setClima] = useState('');
    const [hora, setHora] = useState('0');
    const [isLoggedIn] = useState(false);
    const [rpm, setRpm] = useState('');

    const handleComboboxChangePrecipitacion = (e) => {
        const selectedValue = e.target.value;
        setComboboxPrecipitacion(selectedValue);

        if (selectedValue === "") {
            setPrecipitacion("");
            setIsPrecipitacionManual(true);
        } else {
            setIsPrecipitacionManual(false);
            let randomValue = 0;

            switch (selectedValue) {
                case "0":
                    randomValue = 0;
                    break;
                case "1":
                    randomValue = (Math.random() * 2.4).toFixed(1);
                    break;
                case "2":
                    randomValue = (Math.random() * (10.0 - 2.5) + 2.5).toFixed(1);
                    break;
                case "3":
                    randomValue = (Math.random() * (50.0 - 10.1) + 10.1).toFixed(1);
                    break;
                case "4":
                    randomValue = (Math.random() * (100.0 - 50.1) + 50.1).toFixed(1);
                    break;
                default:
                    randomValue = 0;
            }

            if (randomValue === 0) {
                randomValue = "0";
                setPrecipitacion(randomValue);
            }
            else {
                setPrecipitacion(randomValue);
            }
        }
    };

    const fetchWeatherData = (latitud, longitud) => {
        const locationUrl = `https://dataservice.accuweather.com/locations/v1/cities/geoposition/search?apikey=${API_KEY}&q=${latitud},${longitud}&language=es-EC&details=true&toplevel=false`;

        axios.get(locationUrl)
            .then(locationResponse => {
                const locationKey = locationResponse.data.Key;
                const weatherUrl = `https://dataservice.accuweather.com/currentconditions/v1/${locationKey}?apikey=${API_KEY}&language=es-EC&details=true`;
                return axios.get(weatherUrl);
            })
            .then(weatherResponse => {
                const weatherData = weatherResponse.data[0];
                setClima(weatherData.WeatherIcon);
                setVisibilidad(weatherData.Visibility.Metric.Value);
                setPrecipitacion(weatherData.PrecipitationSummary.Precipitation.Metric.Value);
            })
            .catch(error => {
                setClima(0);
                setVisibilidad(0);
                setPrecipitacion(0);
                alert('No se pudo obtener las condiciones del clima.\nDatos ingresados incorectos.\n' + error.message);
                console.error('Error al obtener los datos del clima:', error);
            });
    };

    const handleComboboxChangePAceleracion = (e) => {
        const selectedValue = e.target.value;
        setComboboxPAceleracion(selectedValue);

        if (selectedValue === "") {
            setPAceleracion("");
            setIsPAceleracionManual(true);
        } else {
            setIsPAceleracionManual(false);
            let randomValue = 0;

            switch (selectedValue) {
                case "0":
                    randomValue = Math.floor(Math.random() * 16);
                    break;
                case "1":
                    randomValue = Math.floor(Math.random() * 5) + 16;
                    break;
                case "2":
                    randomValue = Math.floor(Math.random() * 5) + 21;
                    break;
                case "3":
                    randomValue = Math.floor(Math.random() * 5) + 26;
                    break;
                default:
                    randomValue = 0;
            }

            if (randomValue === 0) {
                randomValue = "0";
                setPAceleracion(randomValue);
            }
            else {
                setPAceleracion(randomValue);
            }
        }
    };

    const handleComboboxChangeAceleracion = (e) => {
        const selectedValue = e.target.value;
        setComboboxAceleracion(selectedValue);

        if (selectedValue === "") {
            setAceleracion("");
            setIsAceleracionManual(true);
        } else {
            setIsAceleracionManual(false);
            let randomValue = 0;

            switch (selectedValue) {
                case "0":
                    randomValue = Math.floor(Math.random() * 16);
                    break;
                case "1":
                    randomValue = Math.floor(Math.random() * 5) + 16;
                    break;
                case "2":
                    randomValue = Math.floor(Math.random() * 5) + 21;
                    break;
                case "3":
                    randomValue = Math.floor(Math.random() * 5) + 26;
                    break;
                default:
                    randomValue = 0;
            }

            if (randomValue === 0) {
                randomValue = "0";
                setAceleracion(randomValue);
            }
            else {
                setAceleracion(randomValue);
            }
        }
    };

    const handleComboboxChangeVisibilidad = (e) => {
        const selectedValue = e.target.value;
        setComboboxVisibilidad(selectedValue);

        if (selectedValue === "") {
            setVisibilidad("");
            setIsVisibilidadManual(true);
        } else {
            setIsVisibilidadManual(false);
            let randomValue = 0;

            switch (selectedValue) {
                case "0":
                    randomValue = 0;
                    break;
                case "1":
                    randomValue = (Math.random() * 2.4).toFixed(1);
                    break;
                case "2":
                    randomValue = (Math.random() * (10.0 - 2.5) + 2.5).toFixed(1);
                    break;
                case "3":
                    randomValue = (Math.random() * (50.0 - 10.1) + 10.1).toFixed(1);
                    break;
                case "4":
                    randomValue = (Math.random() * (100.0 - 50.1) + 50.1).toFixed(1);
                    break;
                default:
                    randomValue = 0;
            }

            if (randomValue === 0) {
                randomValue = "0";
                setVisibilidad(randomValue);
            }
            else {
                setVisibilidad(randomValue);
            }
        }
    };

    /*const handleComboboxChangeDistancia = (e) => {
        const selectedValue = e.target.value;
        setComboboxDistancia(selectedValue);
        setDistancia(selectedValue);
    };*/

    const handleComboboxChangeVelocidad = (e) => {
        const selectedValue = e.target.value;
        setComboboxVelocidad(selectedValue);

        if (selectedValue === "") {
            setVelocidad("");
            setIsVelocidadManual(true);
        } else {
            setIsVelocidadManual(false);
            let randomValue = 0;

            switch (selectedValue) {
                case "0":
                    randomValue = 0;
                    break;
                case "1":
                    randomValue = Math.floor(Math.random() * 11);
                    break;
                case "2":
                    randomValue = Math.floor(Math.random() * 10) + 11;
                    break;
                case "3":
                    randomValue = Math.floor(Math.random() * 20) + 21;
                    break;
                case "4":
                    randomValue = Math.floor(Math.random() * 60) + 41;
                    break;
                default:
                    randomValue = 0;
            }

            if (randomValue === 0) {
                randomValue = "0";
                setVelocidad(randomValue);
            }
            else {
                setVelocidad(randomValue);
            }
        }
    };

    const handleComboboxChangeCardiaco = (e) => {
        const selectedValue = e.target.value;
        setComboboxCardiaco(selectedValue);

        if (selectedValue === "") {
            setCardiaco("");
            setIsCardiacoManual(true);
        } else {
            setIsCardiacoManual(false);
            let randomValue = 0;

            switch (selectedValue) {
                case "0":
                    randomValue = Math.floor(Math.random() * 60);
                    break;
                case "1":
                    randomValue = Math.floor(Math.random() * 21) + 60;
                    break;
                case "2":
                    randomValue = Math.floor(Math.random() * 20) + 81;
                    break;
                case "3":
                    randomValue = Math.floor(Math.random() * 20) + 101;
                    break;
                case "4":
                    randomValue = Math.floor(Math.random() * 60) + 121;
                    break;
                default:
                    randomValue = 0;
            }

            if (randomValue === 0) {
                randomValue = "0";
                setCardiaco(randomValue);
            }
            else {
                setCardiaco(randomValue);
            }
        }
    };

    /*const handleComboboxChangeAngulo = (e) => {
        const selectedValue = e.target.value;
        setComboboxAngulo(selectedValue);
        setAngulo(selectedValue);
    };*/

    const validateNumberPAceleracion = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setPAceleracion(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setPAceleracion('');
        }
    };

    const validateNumberPrecipitacion = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setPrecipitacion(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setPrecipitacion('');
        }
    };

    const handleComboboxChangeTMotor = (e) => {
        const selectedValue = e.target.value;
        setComboboxTMotor(selectedValue);

        if (selectedValue === "") {
            setTmotor("");
            setIsTMotorManual(true);
        } else {
            setIsTMotorManual(false);
            let randomValue = 0;

            switch (selectedValue) {
                case "0":
                    randomValue = Math.floor(Math.random() * 83);
                    break;
                case "1":
                    randomValue = Math.floor(Math.random() * (94 - 83 + 1)) + 83;
                    break;
                case "2":
                    randomValue = Math.floor(Math.random() * (104 - 95 + 1)) + 95;
                    break;
                case "3":
                    randomValue = Math.floor(Math.random() * (200 - 105 + 1)) + 105;
                    break;
                default:
                    randomValue = 0;
            }

            if (randomValue === 0) {
                randomValue = "0";
                setTmotor(randomValue);
            }
            else {
                setTmotor(randomValue);
            }
        }
    };

    const handleComboboxChangeCMotor = (e) => {
        const selectedValue = e.target.value;
        setComboboxCMotor(selectedValue);

        if (selectedValue === "") {
            setCmotor("");
            setIsCMotorManual(true);
        } else {
            setIsCMotorManual(false);
            let randomValue = 0;

            switch (selectedValue) {
                case "0":
                    randomValue = Math.floor(Math.random() * 11);
                    break;
                case "1":
                    randomValue = Math.floor(Math.random() * 3) + 11;
                    break;
                case "2":
                    randomValue = Math.floor(Math.random() * 2) + 14;
                    break;
                case "3":
                    randomValue = Math.floor(Math.random() * 5) + 16;
                    break;
                default:
                    randomValue = 0;
            }

            if (randomValue === 0) {
                randomValue = "0";
                setCmotor(randomValue);
            }
            else {
                setCmotor(randomValue);
            }
        }
    };

    const validateNumberAceleracion = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setAceleracion(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setAceleracion('');
        }
    };

    const handleComboboxChangeClima = (e) => {
        const selectedValue = e.target.value;
        setComboboxClima(selectedValue);

        if (selectedValue === "") {
            setClima("");
            setIsClimadaManual(true);
        } else {
            setIsClimadaManual(false);
        }

        setClima(selectedValue);
    };

    /*const validateNumberDistancia = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setDistancia(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setDistancia('');
        }
    };*/

    const validateNumberVisibilidad = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setVisibilidad(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setVisibilidad('');
        }
    };

    const validateNumberVelocidad = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setVelocidad(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setVelocidad('');
        }
    };

    const handleComboboxChangeRpm = (e) => {
        const selectedValue = e.target.value;
        setComboboxRpm(selectedValue);

        if (selectedValue === "") {
            setRpm("");
            setIsRpmManual(true);
        } else {
            setIsRpmManual(false);
            let randomValue = 0;

            switch (selectedValue) {
                case "0":
                    randomValue = Math.floor(Math.random() * 1501);
                    break;
                case "1":
                    randomValue = Math.floor(Math.random() * 1500) + 1501;
                    break;
                case "2":
                    randomValue = Math.floor(Math.random() * 2000) + 3001;
                    break;
                case "3":
                    randomValue = Math.floor(Math.random() * 3000) + 5001;
                    break;
                default:
                    randomValue = 0;
            }

            if (randomValue === 0) {
                randomValue = "0";
                setRpm(randomValue);
            }
            else {
                setRpm(randomValue);
            }
        }
    };

    const handleCaptchaChange = (value) => {
        if(value)
        {
            setCaptchaValido(true);
        }
    };

    const validateNumberLongitud = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setLongitud(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setLongitud('');
        }
    };

    const validateNumberCardiaco = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setCardiaco(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setCardiaco('');
        }
    };

    /*const validateNumberAngulo = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setAngulo(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setAngulo('');
        }
    };*/

    const validateNumberLatitud = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setLatitud(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setLatitud('');
        }
    };

    const obtenerColorFondo = (nivel) => {
        switch (nivel) {
            case 1:
                return 'green';
            case 2:
                return 'yellow';
            case 3:
                return 'orange';
            case 4:
                return 'red';
            default:
                return 'transparent';
        }
    };

    /*const handleSignOut = (signOut) => {
        signOut();
        setIsLoggedIn(false);
        window.history.back();
        window.location.href = "/main";
    };*/

    const validateNumberTmotor = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setTmotor(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setTmotor('');
        }
    };

    const validateNumberCmotor = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setCmotor(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setCmotor('');
        }
    };

    const validateNumberOnSite = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setOnsite(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setOnsite('');
        }
    };

    const validateNumberDesing = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setDesing(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setDesing('');
        }
    };

    const validateNumberOnHOur = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setOnHour(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setOnHour('');
        }
    };

    const handleCalculateClick = () => {
        if (captchaValido) {
            recaptchaRef.current.reset();
            setCaptchaValido(false);
        }
        else{
            alert("Por favor, completa el reCAPTCHA.");
            setMostrarEtiqueta(true);
            return;
        }

        if (validateInputs()) {
            setMostrarEtiqueta(false);

            // [['steering_angle_', 'speed', 'rpm_', 'acceleration', 'throttle_position', 'engine_temperature','system_voltage', 'heart_rate', 'distance_travelled', 'latitude','longitude', 'current_weather', 'accidents_onsite']]
            // [[     -10.2,          91,     4500,    1.785648,          15.89768,           94,                   13.2,             60,             15.38988989,    -0.324568329, -78.37786345, 2,                 27]]
            // const data = '{ "Input": [[-10.2, 91, 4500, 1.785648, 15.89768, 94, 13.2, 60, 15.38988989, -0.324568329, -78.37786345, 2, 27]] }';
            const data = JSON.stringify({
                "Input": [[
                    parseFloat(99),
                    parseFloat(velocidad),
                    parseFloat(rpm),
                    parseFloat(aceleracion),
                    parseFloat(paceleracion),
                    parseFloat(tmotor),
                    parseFloat(cmotor),
                    parseFloat(cardiaco),
                    parseFloat(99),
                    parseFloat(latitud),
                    parseFloat(longitud),
                    parseFloat(clima),
                    parseFloat(onsite)
                ]]
            });

            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://wsouotfzr3.execute-api.us-east-1.amazonaws.com/FaseBeta/Recursos_Predictor_Accidentes',
                data: data
            };

            axios(config)
                .then(response => {
                    const respuestaAgente = JSON.stringify(response.data);
                    const parsedData = JSON.parse(respuestaAgente);
                    const numero = parsedData.Output;
                    console.log(respuestaAgente);
                    alert(respuestaAgente);
                    if (numero === 1) { setResultado('Bajo'); }

                    if (numero === 2) { setResultado('Medio'); }

                    if (numero === 3) { setResultado('Alto'); }

                    if (numero === 4) { setResultado('Muy Alto'); }

                    setColor(obtenerColorFondo(numero));
                })
                .catch(error => {
                    console.log(error);
                    setResultado('Error al calcular el nivel de riesgo');
                    setColor('red');
                    alert('No se pudo establecer conexión o ocurrió un error: ' + error.message);
                }).finally(() => {
                    setIsPrecipitacionManual(true);
                    setIsPAceleracionManual(true);
                    setComboboxPrecipitacion('');
                    setIsAceleracionManual(true);
                    setIsVisibilidadManual(true);
                    setComboboxPAceleracion('');
                    setComboboxVisibilidad('');
                    setComboboxAceleracion('');
                    //setComboboxDistancia('');
                    setIsVelocidadManual(true);
                    setIsCardiacoManual(true);
                    setComboboxVelocidad('');
                    setIsClimadaManual(true);
                    setComboboxCardiaco('');
                    //setComboboxAngulo('');
                    setIsTMotorManual(true);
                    setIsCMotorManual(true);
                    setComboboxTMotor('');
                    setComboboxCMotor('');
                    setPrecipitacion('');
                    setComboboxClima('');
                    setIsRpmManual(true);
                    setPAceleracion('');
                    setCargando(false);
                    setAceleracion('');
                    setVisibilidad('');
                    setComboboxRpm('');
                    //setDistancia(''); 
                    setVelocidad('');
                    setCardiaco('');
                    setLongitud('');
                    //setAngulo('');
                    setLatitud('');
                    setTmotor('');
                    setCmotor('');
                    setOnsite('');
                    setDesing('');
                    setOnHour('');
                    setClima('');
                    setHora('0');
                    setRpm('');
                });
        }
        else {
            setMostrarEtiqueta(true);
        }
    };

    const validateNumberClima = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setClima(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setClima('');
        }
    };

    const validateNumberRpm = (e) => {
        const value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (regex.test(value) || value === '') {
            setRpm(value);
        } else {
            alert('Por favor, ingrese solo números.');
            setRpm('');
        }
    };

    const handleDeleteClick = () => {
        setColor(obtenerColorFondo(0));
        setIsPrecipitacionManual(true);
        setResultado('Calculando...');
        setIsPAceleracionManual(true);
        setComboboxPrecipitacion('');
        setIsAceleracionManual(true);
        setIsVisibilidadManual(true);
        setComboboxPAceleracion('');
        setComboboxVisibilidad('');
        setComboboxAceleracion('');
        //setComboboxDistancia('');
        setIsVelocidadManual(true);
        setIsCardiacoManual(true);
        setComboboxVelocidad('');
        setIsClimadaManual(true);
        setComboboxCardiaco('');
        //setComboboxAngulo('');
        setIsTMotorManual(true);
        setIsCMotorManual(true);
        setComboboxTMotor('');
        setComboboxCMotor('');
        setPrecipitacion('');
        setComboboxClima('');
        setIsRpmManual(true);
        setPAceleracion('');
        setCargando(false);
        setAceleracion('');
        setVisibilidad('');
        setComboboxRpm('');
        //setDistancia(''); 
        setVelocidad('');
        setCardiaco('');
        setLongitud('');
        //setAngulo('');
        setLatitud('');
        setTmotor('');
        setCmotor('');
        setOnsite('');
        setDesing('');
        setOnHour('');
        setClima('');
        setHora('0');
        setRpm(''); 
    };

    const validateInputs = () => {
        const camposFaltantes = [];

        if (!paceleracion) camposFaltantes.push("Posición acelerador");
        if (!tmotor) camposFaltantes.push("Temperatura motor");
        if (!onsite) camposFaltantes.push("Accidentes sitio");
        if (!desing) camposFaltantes.push("Velocidad diseño");
        if (!cardiaco) camposFaltantes.push("Ritmo cardiaco");
        if (!aceleracion) camposFaltantes.push("Aceleración");
        if (!onhour) camposFaltantes.push("Accidentes hora");
        if (!velocidad) camposFaltantes.push("Velocidad");
        if (!cmotor) camposFaltantes.push("Carga motor");
        if (!longitud) camposFaltantes.push("Longitud");
        if (!latitud) camposFaltantes.push("Latitud");
        if (!clima) camposFaltantes.push("Clima");
        if (!rpm) camposFaltantes.push("Rpm");

        if (camposFaltantes.length > 0) {
            alert(`Por favor, llena los siguientes campos: ${camposFaltantes.join(", ")}`);
            return false;
        }
        return true;
    };

    const comboLabelsFinal = [
        "Km/h",
        "rev/min",
        "m/s²",
        "%",
        "°C",
        "%",
        "°",
        "Km",
        "lpm",
        "mm"
    ];

    const comboLabels = [
        "Velocidad ",
        "Revoluciones ",
        "Aceleración ",
        "Pos. Acelerador ",
        "Temp. Motor ",
        "Carga Motor ",
        "Áng. Dirección ",
        "Dist. Recorrida ",
        "Acc. en Sitio ",
        "Ritmo Cardiaco ",
        "Clima ",
        "Visivilidad ",
        "Precipitación ",
        "Latitud ",
        "Longitud ",
        "Hora ",
        "Accidentes sitio ",
        "Velocidad diseño ",
        "Accidentes hora "
    ];

    const comboInfo = [
        "servicio@polidriving.com",
        "Departamento de Informatica y Ciencias de la Computación (DICC)",
        "Escuela Politécnica Nacional",
        "Ladron de Guevara E11-253",
        "Quito",
        " "
    ];

    return (
        <div style={{ margin: '100px', marginBottom: '10px', marginLeft: '100px', marginRight: '-100px', position: 'relative', paddingBottom: '0px' }}>
            <img
                src="/resources/Start_3.png"
                alt="Logo"
                style={{ position: 'absolute', top: '-130px', left: '-150px', width: '90px', height: '90px' }}
            />
            {/*
            <h1 style={{ textAlign: 'center', fontSize: '48px', fontWeight: 'bold', marginTop: '-100px' }}>
                {isLoggedIn ? "Bienvenido a POLIDriving" : "POLIDriving"}
            </h1>
            */}
            <h1 style={{ textAlign: 'left', fontSize: '48px', fontWeight: 'bold', marginTop: '-140px', marginLeft: '-50px' }}>
                POLIDriving WebApp
            </h1>
            <h3 style={{ textAlign: 'left', fontSize: '20px', fontWeight: 'bold', marginTop: '-40px', marginLeft: '-50px' }}>
                Sevicio en línea para calcular el nivel de riesgo al conducir
            </h3>
            <h1 style={{ textAlign: 'left', fontSize: '10px', marginTop: '-10px', marginLeft: '-150px' }}>
                {'–'.repeat(260)}
            </h1>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {
                    //!isLoggedIn ? ( 
                    isLoggedIn ? (
                        <div style={{ flex: 1, marginRight: '50px' }}>
                            <p style={{ textAlign: 'justify' }}>
                                El cálculo de los niveles de riesgo en la aplicación desarrollada se basa en un análisis exhaustivo de múltiples variables que influyen en la siniestralidad vial.
                                Se recopilan datos en tiempo real sobre factores como el comportamiento del conductor, las condiciones de la carretera, y el historial de accidentes en las áreas geográficas relevantes.
                                Utilizando algoritmos de machine learning, se procesan estos datos para identificar patrones y correlaciones que permitan clasificar los niveles de riesgo en diferentes rangos, desde bajo hasta alto.
                                Esta metodología no solo ayuda a anticipar situaciones potencialmente peligrosas, sino que también permite generar alertas proactivas que informan a los conductores sobre condiciones adversas,
                                contribuyendo así a la prevención de accidentes y la mejora de la seguridad vial. Además, el sistema se ajusta continuamente a medida que se recopilan más datos, mejorando su precisión y efectividad a lo largo del tiempo.
                            </p>

                            <p style={{ textAlign: 'justify' }}>
                                Para acceder a las funcionalidades de la aplicación, los usuarios deben registrarse o iniciar sesión a través de la plataforma móvil o web.
                                Este proceso de autenticación asegura que la información personal y los datos recopilados se manejen de manera segura, permitiendo a los usuarios gestionar su perfil
                                y acceder a las herramientas de análisis de riesgo. Al registrarse, los usuarios también pueden recibir notificaciones personalizadas y alertas relacionadas con su seguridad vial,
                                lo que mejora la experiencia y efectividad de la aplicación.
                            </p>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                            <div style={{ flex: 1, marginRight: '-30px', marginLeft: '-150px' }}>
                                {/* 
                                <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', marginLeft: '30px' }}>
                                    <label style={{ marginRight: '10px' }}>
                                        <input
                                            type="checkbox"
                                            checked={isManualMode}
                                            onChange={() => {
                                                setIsManualMode(!isManualMode);
                                                setComboboxPrecipitacion('');
                                                setComboboxPAceleracion('');
                                                setComboboxVisibilidad('');
                                                setComboboxAceleracion('');
                                                setComboboxDistancia('');
                                                setComboboxVelocidad('');
                                                setComboboxCardiaco('');
                                                setComboboxAngulo('');
                                                setComboboxTMotor('');
                                                setComboboxCMotor('');
                                                setPrecipitacion('');
                                                setComboboxSitio('');
                                                setComboboxClima('');
                                                setPAceleracion('');
                                                setCargando(false);
                                                setAceleracion('');
                                                setVisibilidad('');
                                                setComboboxRpm('');
                                                setDistancia('');
                                                setVelocidad('');
                                                setCardiaco('');
                                                setLongitud('');
                                                setLatitud('');
                                                setTmotor('');
                                                setCmotor('');
                                                setAngulo('');
                                                setOnsite('');
                                                setDesing('');
                                                setOnHour('');
                                                setClima('');
                                                setHora('0');
                                                setRpm('');
                                            }}
                                        />
                                        Manual/Rangos
                                    </label>
                                </div>
                                */}

                                {/* Latitud */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[13]}:</label>
                                    <input
                                        type="text"
                                        placeholder=""
                                        value={latitud}
                                        onChange={(e) => validateNumberLatitud(e, setLatitud)}
                                        style={{ marginRight: '10px', width: '200px', height: '22px', fontSize: '13px' }}
                                    />
                                </div>

                                {/* Longitud */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[14]}:</label>
                                    <input
                                        type="text"
                                        placeholder=""
                                        value={longitud}
                                        onChange={(e) => validateNumberLongitud(e, setLongitud)}
                                        onBlur={() => {
                                            if (latitud && longitud) {
                                                fetchWeatherData(latitud, longitud);
                                            }
                                        }}
                                        style={{ marginRight: '10px', width: '200px', height: '22px', fontSize: '13px' }}
                                    />
                                </div>

                                {/* Hora */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[15]}:</label>
                                    <select
                                        value={hora}
                                        onChange={(e) => setHora(e.target.value)}
                                        style={{ width: '200px', height: '22px', fontSize: '13px' }}
                                    >
                                        {[...Array(24)].map((_, i) => (
                                            <option key={i} value={i}>{`${i.toString().padStart(2, '0')}:00`}</option>
                                        ))}
                                    </select>
                                </div>

                                {/* Añadir separador */}
                                <div style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                        <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>
                                            {'–'.repeat(95)}
                                        </label>
                                    </div>
                                </div>

                                {/* OnSite */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[16]}:</label>
                                    <input
                                        type="text"
                                        placeholder=""
                                        value={onsite}
                                        onChange={(e) => validateNumberOnSite(e, setOnsite)}
                                        style={{ marginRight: '10px', width: '200px', height: '22px', fontSize: '13px' }}
                                    />
                                </div>

                                {/* Vel. Diseño */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[17]}:</label>
                                    <input
                                        type="text"
                                        placeholder=""
                                        value={desing}
                                        onChange={(e) => validateNumberDesing(e, setDesing)}
                                        style={{ marginRight: '10px', width: '200px', height: '22px', fontSize: '13px' }}
                                    />
                                    <label style={{ width: '75px', fontSize: '13px', color: 'red' }}>{comboLabelsFinal[0]}</label>
                                </div>

                                {/* OnHour */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[18]}:</label>
                                    <input
                                        type="text"
                                        placeholder=""
                                        value={onhour}
                                        onChange={(e) => validateNumberOnHOur(e, setOnHour)}
                                        style={{ marginRight: '10px', width: '200px', height: '22px', fontSize: '13px' }} />
                                </div>

                                {/* Añadir separador */}
                                <div style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                        <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>
                                            {'–'.repeat(95)}
                                        </label>
                                    </div>
                                </div>

                                {/* Velocidad */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[0]}:</label>
                                    <select
                                        style={{ width: '200px', height: '22px', fontSize: '13px' }}
                                        onChange={handleComboboxChangeVelocidad}
                                        value={comboboxVelocidad}
                                    //disabled={isManualMode}
                                    >
                                        <option value="">Ingresar Manualmente</option>
                                        <option value={0}>Normal:    [0-0]</option>
                                        <option value={1}>Ligera:    [1-10]</option>
                                        <option value={2}>Moderada:  [11-20]</option>
                                        <option value={3}>Seria:     [21-40]</option>
                                        <option value={4}>Muy Seria: [41-100]</option>
                                    </select>
                                    <input
                                        type="text"
                                        placeholder={``}
                                        value={velocidad}
                                        onChange={validateNumberVelocidad}
                                        style={{ marginLeft: '25px', marginRight: '25px', width: '200px', height: '22px', fontSize: '13px' }}
                                        disabled={!isVelocidadManual}
                                    //disabled={!isManualMode}
                                    />
                                    <label style={{ marginLeft: '-30px', marginRight: '-30px', width: '75px', fontSize: '13px', color: 'red' }}>{comboLabelsFinal[0]}</label>
                                </div>

                                {/* RPM */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[1]}:</label>
                                    <select
                                        style={{ width: '200px', height: '22px', fontSize: '13px' }}
                                        onChange={handleComboboxChangeRpm}
                                        value={comboboxRpm}
                                    //disabled={isManualMode}
                                    >
                                        <option value="">Ingresar Manualmente</option>
                                        <option value={0}>Bajo:     [0-1500]</option>
                                        <option value={1}>Normal:   [1501-3000]</option>
                                        <option value={2}>Alto:     [3001-5000]</option>
                                        <option value={3}>Muy ALto: [5001-8000]</option>
                                    </select>
                                    <input
                                        type="text"
                                        placeholder={``}
                                        value={rpm}
                                        onChange={validateNumberRpm}
                                        style={{ marginLeft: '25px', marginRight: '25px', width: '200px', height: '22px', fontSize: '13px' }}
                                        disabled={!isRpmManual}
                                    //disabled={!isManualMode}
                                    />
                                    <label style={{ marginLeft: '-30px', marginRight: '-30px', width: '75px', fontSize: '13px', color: 'red' }}>{comboLabelsFinal[1]}</label>
                                </div>

                                {/* Aceleración */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[2]}:</label>
                                    <select
                                        style={{ width: '200px', height: '22px', fontSize: '13px' }}
                                        onChange={handleComboboxChangeAceleracion}
                                        value={comboboxAceleracion}
                                    //disabled={isManualMode}
                                    >
                                        <option value="">Ingresar Manualmente</option>
                                        <option value={0}>Bajo:     [0-15]</option>
                                        <option value={1}>Normal:   [16-20]</option>
                                        <option value={2}>Alto:     [21-25]</option>
                                        <option value={3}>Muy ALto: [26-30]</option>
                                    </select>
                                    <input
                                        type="text"
                                        placeholder={``}
                                        value={aceleracion}
                                        onChange={validateNumberAceleracion}
                                        style={{ marginLeft: '25px', marginRight: '25px', width: '200px', height: '22px', fontSize: '13px' }}
                                        disabled={!isAceleracionManual}
                                    //disabled={!isManualMode}
                                    />
                                    <label style={{ marginLeft: '-30px', marginRight: '-30px', width: '75px', fontSize: '13px', color: 'red' }}>{comboLabelsFinal[2]}</label>
                                </div>

                                {/* Posición del Acelerador */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[3]}:</label>
                                    <select
                                        style={{ width: '200px', height: '22px', fontSize: '13px' }}
                                        onChange={handleComboboxChangePAceleracion}
                                        value={comboboxPAceleracion}
                                    //disabled={isManualMode}
                                    >
                                        <option value="">Ingresar Manualmente</option>
                                        <option value={0}>Bajo:     [0-15]</option>
                                        <option value={1}>Normal:   [16-20]</option>
                                        <option value={2}>Alto:     [21-25]</option>
                                        <option value={3}>Muy ALto: [26-30]</option>
                                    </select>
                                    <input
                                        type="text"
                                        placeholder={``}
                                        value={paceleracion}
                                        onChange={validateNumberPAceleracion}
                                        style={{ marginLeft: '25px', marginRight: '25px', width: '200px', height: '22px', fontSize: '13px' }}
                                        disabled={!isPAceleracionManual}
                                    //disabled={!isManualMode}
                                    />
                                    <label style={{ marginLeft: '-30px', marginRight: '-30px', width: '75px', fontSize: '13px', color: 'red' }}>{comboLabelsFinal[3]}</label>
                                </div>

                                {/* Temperatura del Motor */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[4]}:</label>
                                    <select
                                        style={{ width: '200px', height: '22px', fontSize: '13px' }}
                                        onChange={handleComboboxChangeTMotor}
                                        value={comboboxTMotor}
                                    //disabled={isManualMode}
                                    >
                                        <option value="">Ingresar Manualmente</option>
                                        <option value={0}>Bajo:               [0-82]</option>
                                        <option value={1}>Nornal:             [83-94]</option>
                                        <option value={2}>Alto:               [95-104]</option>
                                        <option value={3}>Sobrecalentamiento: [105-200]</option>
                                    </select>
                                    <input
                                        type="text"
                                        placeholder={``}
                                        value={tmotor}
                                        onChange={validateNumberTmotor}
                                        style={{ marginLeft: '25px', marginRight: '25px', width: '200px', height: '22px', fontSize: '13px' }}
                                        disabled={!isTMotorManual}
                                    //disabled={!isManualMode}
                                    />
                                    <label style={{ marginLeft: '-30px', marginRight: '-30px', width: '75px', fontSize: '13px', color: 'red' }}>{comboLabelsFinal[4]}</label>
                                </div>

                                {/* Carga del Motor */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[5]}:</label>
                                    <select
                                        style={{ width: '200px', height: '22px', fontSize: '13px' }}
                                        onChange={handleComboboxChangeCMotor}
                                        value={comboboxCMotor}
                                    //disabled={isManualMode}
                                    >
                                        <option value="">Ingresar Manualmente</option>
                                        <option value={0}>Bajo:     [0-10]</option>
                                        <option value={1}>Normal:   [1-13]</option>
                                        <option value={2}>Alto:     [13-15]</option>
                                        <option value={3}>Muy ALto: [15-20]</option>
                                    </select>
                                    <input
                                        type="text"
                                        placeholder={``}
                                        value={cmotor}
                                        onChange={validateNumberCmotor}
                                        style={{ marginLeft: '25px', marginRight: '25px', width: '200px', height: '22px', fontSize: '13px' }}
                                        disabled={!isCMotorManual}
                                    //disabled={!isManualMode}
                                    />
                                    <label style={{ marginLeft: '-30px', marginRight: '-30px', width: '75px', fontSize: '13px', color: 'red' }}>{comboLabelsFinal[3]}</label>
                                </div>

                                {/* Ángulo de Dirección */}
                                {/*
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[6]}:</label>
                                    <select
                                        style={{ width: '200px', height: '22px', fontSize: '13px' }}
                                        onChange={handleComboboxChangeAngulo}
                                        value={comboboxAngulo}
                                    //disabled={isManualMode}
                                    >
                                        <option value="">Ingresar Manualmente</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10</option>
                                    </select>
                                    <input
                                        type="text"
                                        placeholder={``}
                                        value={angulo}
                                        onChange={validateNumberAngulo}
                                        style={{ marginLeft: '25px', marginRight: '25px', width: '200px', height: '22px', fontSize: '13px' }}
                                        //disabled={!isManualMode}
                                    />
                                </div>
                                */}

                                {/* Distancia Reccorida */}
                                {/*
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[7]}:</label>
                                    <select
                                        style={{ width: '200px', height: '22px', fontSize: '13px' }}
                                        onChange={handleComboboxChangeDistancia}
                                        value={comboboxDistancia}
                                    //disabled={isManualMode}
                                    >
                                        <option value="">Ingresar Manualmente</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10</option>
                                    </select>
                                    <input
                                        type="text"
                                        placeholder={``}
                                        value={distancia}
                                        onChange={validateNumberDistancia}
                                        style={{ marginLeft: '25px', marginRight: '25px', width: '200px', height: '22px', fontSize: '13px' }}
                                        //disabled={!isManualMode}
                                    />
                                </div>
                                */}

                                {/* Añadir separador */}
                                <div style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                        <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>
                                            {'–'.repeat(95)}
                                        </label>
                                    </div>
                                </div>

                                {/* Ritmo Cardiaco */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[9]}:</label>
                                    <select
                                        style={{ width: '200px', height: '22px', fontSize: '13px' }}
                                        onChange={handleComboboxChangeCardiaco}
                                        value={comboboxCardiaco}
                                    //disabled={isManualMode}
                                    >
                                        <option value="">Ingresar Manualmente</option>
                                        <option value={0}>Bradicardia:        [0-59]</option>
                                        <option value={1}>Sinus zona a:       [60-80]</option>
                                        <option value={2}>Sinus zona b:       [81-100]</option>
                                        <option value={3}>Tachycardia Slight: [101-120]</option>
                                        <option value={4}>Tachycardia Severe: [121-180]</option>
                                    </select>
                                    <input
                                        type="text"
                                        placeholder={``}
                                        value={cardiaco}
                                        onChange={validateNumberCardiaco}
                                        style={{ marginLeft: '25px', marginRight: '25px', width: '200px', height: '22px', fontSize: '13px' }}
                                        disabled={!isCardiacoManual}
                                    //disabled={!isManualMode}
                                    />
                                    <label style={{ marginLeft: '-30px', marginRight: '-30px', width: '75px', fontSize: '13px', color: 'red' }}>{comboLabelsFinal[8]}</label>
                                </div>

                                {/* Añadir separador */}
                                <div style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                        <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>
                                            {'–'.repeat(95)}
                                        </label>
                                    </div>
                                </div>

                                {/* Clima */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[10]}:</label>
                                    <select
                                        style={{ width: '200px', height: '22px', fontSize: '13px' }}
                                        onChange={handleComboboxChangeClima}
                                        value={comboboxClima}
                                    //disabled={isManualMode}
                                    >
                                        <option value="">Ingresar Manualmente</option>
                                        <option value={1}>Soleado:               [1]</option>
                                        <option value={2}>Mayormente Soleado:    [2]</option>
                                        <option value={3}>Parcialmente Soleado:  [3]</option>
                                        <option value={5}>Sol Brumoso:           [5]</option>
                                        <option value={6}>Mayormente Nublado:    [6]</option>
                                        <option value={7}>Nublado:               [7]</option>
                                        <option value={9}>Nubes y Sol:           [9]</option>
                                        <option value={11}>Niebla:               [11]</option>
                                        <option value={18}>Lluvia:               [18]</option>
                                        <option value={35}>Parcialmente Nublado: [35]</option>
                                    </select>
                                    <input
                                        type="text"
                                        placeholder={``}
                                        value={clima}
                                        //readOnly
                                        onChange={validateNumberClima}
                                        style={{ marginLeft: '25px', marginRight: '25px', width: '200px', height: '22px', fontSize: '13px' }}
                                        disabled={!isClimaManual}
                                    //disabled={!isManualMode}
                                    />
                                </div>

                                {/* Visibilidad */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[11]}:</label>
                                    <select
                                        style={{ width: '200px', height: '22px', fontSize: '13px' }}
                                        onChange={handleComboboxChangeVisibilidad}
                                        value={comboboxVisibilidad}
                                    //disabled={isManualMode}
                                    >
                                        <option value="">Ingresar Manualmente</option>
                                        <option value={0}>Mala:      [0-0]</option>
                                        <option value={1}>Pobre:     [0.1-2.4]</option>
                                        <option value={2}>Moderada:  [2.5-10.0]</option>
                                        <option value={3}>Buena:     [10.1-50.0]</option>
                                        <option value={4}>Excelente: [50.1-100.0]</option>
                                    </select>
                                    <input
                                        type="text"
                                        placeholder={``}
                                        value={visibilidad}
                                        //readOnly
                                        onChange={validateNumberVisibilidad}
                                        style={{ marginLeft: '25px', marginRight: '25px', width: '200px', height: '22px', fontSize: '13px' }}
                                        disabled={!isVisibilidadManual}
                                    //disabled={!isManualMode}
                                    />
                                    <label style={{ marginLeft: '-30px', marginRight: '-30px', width: '75px', fontSize: '13px', color: 'red' }}>{comboLabelsFinal[7]}</label>
                                </div>

                                {/* Precipitación */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>{comboLabels[12]}:</label>
                                    <select
                                        style={{ width: '200px', height: '22px', fontSize: '13px' }}
                                        onChange={handleComboboxChangePrecipitacion}
                                        value={comboboxPrecipitacion}
                                    //disabled={isManualMode}
                                    >
                                        <option value="">Ingresar Manualmente</option>
                                        <option value={0}>Ninguna:  [0.0-0.0]</option>
                                        <option value={1}>Ligera:   [0.1-2.4]</option>
                                        <option value={2}>Moderada: [2.5-10.0]</option>
                                        <option value={3}>Pesada:   [10.1-50.0]</option>
                                        <option value={4}>Violenta: [50.1-100.0]</option>
                                    </select>
                                    <input
                                        type="text"
                                        placeholder={``}
                                        value={precipitacion}
                                        //readOnly
                                        onChange={validateNumberPrecipitacion}
                                        style={{ marginLeft: '25px', marginRight: '25px', width: '200px', height: '22px', fontSize: '13px' }}
                                        disabled={!isPrecipitacionManual}
                                    //disabled={!isManualMode}
                                    />
                                    <label style={{ marginLeft: '-30px', marginRight: '-300px', width: '75px', fontSize: '13px', color: 'red' }}>{comboLabelsFinal[9]}</label>
                                </div>

                                {/* Añadir separador */}
                                <div style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                        <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>
                                            {'–'.repeat(200)}
                                        </label>
                                    </div>
                                </div>

                                {/* reCAPTCHA */}
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: '20px' }}>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6LfwVXYqAAAAAKB9S1HkKwMmBp1cisyFWveXg_s9"
                                        onChange={handleCaptchaChange}
                                    />
                                    {/* Información Campos */}
                                    {mostrarEtiqueta && (
                                        <label
                                            style={{ marginLeft: '-190px', marginBottom: '-150px', color: 'red', fontSize: '13px' }}
                                        >
                                            Ingresar valores para todos los campos y realiza el reCAPTCHA par realizar la consulta
                                        </label>
                                    )}
                                </div>

                                {/* Añadir separador */}
                                <div style={{ marginBottom: '60px', marginTop: '60px' }}>
                                    <div style={{ marginBottom: '-50px', marginTop: '5px', display: 'flex', alignItems: 'center' }}>
                                        <label style={{ marginRight: '10px', width: '130px', fontSize: '13px' }}>
                                            {'–'.repeat(200)}
                                        </label>
                                    </div>
                                </div>

                                {/* Información */}
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'left' }}>
                                    <label style={{ marginLeft: '10px', width: '100px', fontSize: '13px', color: 'blue' }}>{comboInfo[0]}</label>
                                </div>
                                <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'left' }}>
                                    <label style={{ marginRight: '10px', width: '100px', fontSize: '13px' }}>{comboInfo[5]}</label>
                                </div>
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'left' }}>
                                    <label style={{ marginLeft: '0px', width: '400px', fontSize: '13px' }}>{comboInfo[1]}</label>
                                </div>
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'left' }}>
                                    <label style={{ marginLeft: '-10px', width: '200px', fontSize: '13px' }}>{comboInfo[2]}</label>
                                </div>
                                <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'left' }}>
                                    <label style={{ marginRight: '10px', width: '100px', fontSize: '13px' }}>{comboInfo[5]}</label>
                                </div>
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'left' }}>
                                    <label style={{ marginLeft: '-10px', width: '200px', fontSize: '13px' }}>{comboInfo[3]}</label>
                                </div>
                                <div style={{ marginBottom: '5px', marginTop: '5px', display: 'flex', alignItems: 'left' }}>
                                    <label style={{ marginLeft: '-25px', width: '100px', fontSize: '13px' }}>{comboInfo[4]}</label>
                                </div>
                            </div>

                            {/* Mapa */}
                            <div style={{ flex: 1, marginLeft: '60px', marginTop: '10px', maxWidth: '1000px' }}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d958.4850543185325!2d-78.49018929087231!3d-0.21029711980716012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d59a107b70b7bd%3A0x54a537873ca87a03!2sFacultad%20de%20Ingenier%C3%ADa%20en%20Sistemas!5e1!3m2!1ses!2sec!4v1728917001197!5m2!1ses!2sec"
                                    style={{ width: '650px', height: '520px', border: '2px solid gray', padding: '10px' }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                    title="Mapa Facultad de Ingeniería en Sistemas">
                                </iframe>
                            </div>

                            {/* Sección de Resultados */}
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                {/* Botón Borrar */}
                                <button
                                    style={{
                                        backgroundColor: '#3a75fc ',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '10px',
                                        padding: '10px 26px',
                                        fontSize: '16px',
                                        position: 'absolute',
                                        bottom: '180px',
                                        left: '1050px',
                                        marginBottom: '3x   0px'
                                    }}
                                    onClick={handleDeleteClick}
                                >
                                    Borrar
                                </button>

                                {/* Botón Calcular */}
                                <button
                                    style={{
                                        backgroundColor: '#3a75fc ',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '10px',
                                        padding: '10px 20px',
                                        fontSize: '16px',
                                        position: 'absolute',
                                        bottom: '150px',
                                        left: '-150px',
                                        marginBottom: '30px'
                                    }}
                                    //disabled={!captchaValido}
                                    onClick={handleCalculateClick}
                                >
                                    Calcular
                                </button>

                                {/* Etiqueta Nivel Riesgo */}
                                <div
                                    style={{
                                        margin: '10px 0',
                                        fontSize: '25px',
                                        position: 'absolute',
                                        bottom: '300px',
                                        left: '45%',
                                        marginBottom: '-25px',
                                        fontWeight: 'bold'
                                    }}>
                                    Nivel de riesgo:
                                </div>

                                {/* Etiqueta Resultado */}
                                <div
                                    style={{
                                        backgroundColor: color,
                                        color: 'black',
                                        padding: '10px 10px',
                                        fontSize: '25px',
                                        position: 'absolute',
                                        bottom: '260px',
                                        left: '61%',
                                        marginBottom: '5px',
                                        fontWeight: 'bold'
                                    }}>
                                    {cargando ? 'Calculando...' : resultado}
                                </div>
                            </div>
                        </div>
                    )
                }

                <div style={{ flex: 1, textAlign: 'center', marginLeft: '50px', marginTop: '40px' }}>
                    {/* Botón Salir y Sistema de Inicio de Sesión */}
                    {/*
                    <Authenticator>
                        {
                            ({ signOut, user }) => (
                                user ? (
                                    <div>
                                        {setIsLoggedIn(true)}
                                        <button
                                            onClick={() => handleSignOut(signOut)}
                                            style={{
                                                padding: '10px 100px',
                                                fontSize: '25px',
                                                position: 'absolute',
                                                bottom: '-30px',
                                                left: '50%',
                                                transform: 'translateX(-50%)'
                                            }} >
                                            Salir
                                        </button>
                                    </div>
                                ) : null
                            )
                        }
                    </Authenticator>
                    */}
                </div>
            </div>
        </div>
    );
};

export default Main;