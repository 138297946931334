import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
//import Home from './pages/Home';
//import About from './pages/About';
import Main from './pages/Main';

function SplashScreen() {
  return (
    <div className="splash-screen">
    </div>
  );
}

function App() {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 4500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {showSplash ? (
        <SplashScreen />
      ) : (
        <Router>
          <div className='content'>
            {/*
            <NavLink className='content' exact activeClassName="active" to="/">Principal</NavLink>
            <NavLink className='content' exact activeClassName="active" to="/about">Sobre Nosotros</NavLink>
            <NavLink className='content' exact activeClassName="active" to="/main">Niveles de Riesgo</NavLink>
            */}
          </div>
          <Routes>
            {/*
            <Route path="/" element={<Home />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/main" element={<Main />}></Route>
            */}
            <Route path="/" element={<Main />}></Route>
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;