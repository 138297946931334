/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9c6f3b5c-e21d-4613-bccb-d40454e4cf09",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_73P8iZCEk",
    "aws_user_pools_web_client_id": "6b7j4s0l93mnl5203nk5td1jqe",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "obdbucket104346-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};

export default awsmobile;

/*
const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:e89f6809-9e0e-439f-9d08-3c5ae1c969c5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_efap3kCnt",
    "aws_user_pools_web_client_id": "5274vqotkb4a1p18qvo8jq6o76",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};
*/